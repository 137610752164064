/* ======= CLASSES UTILITAIRES ======= */
.light-pink { color: var(--light) }
.medium-pink { color: var(--medium) }
.dark-pink { color: var(--dark) }
.white { color : var(--white) }

.kalam { font-family: 'Kalam', serif }

.fs-0 { font-size: 1rem }
.fs-1 { font-size: 1.5rem }
.fs-2 { font-size: 2rem }
.fs-3 { font-size: 2.25rem }
.fs-4 { font-size: 3rem }
.fs-5 { font-size: 4rem }
.fs-6 { font-size: 6rem }
.fs-7 { font-size: 8rem }

.bold { font-weight: 700 }
.light { font-weight: 300 }

.center { text-align: center }

.justify { text-align: justify }
.grid { display: grid }
.flex { display: flex }
.column { flex-direction: column }
.wrap { flex-wrap: wrap }
.jcfs { justify-content: flex-start }
.jcfe { justify-content: flex-end }
.jcc { justify-content: center }
.jcsb { justify-content: space-between }
.jcsa { justify-content: space-around }
.aic { align-items: center }